var Confirm, clipCtl, fixHeader, fixSidebar, headerMenuLink, i, initializeFacebookSDK, jsClip, loadFacebookSDK, loadTwitterSDK, ready, triggerClip;

headerMenuLink = document.querySelectorAll('.js-e-headermenu');

if (headerMenuLink.length) {
  i = 0;
  while (i < headerMenuLink.length) {
    headerMenuLink[i].addEventListener('click', (function(e) {
      var nowActive;
      if (!hasClass(this.parentNode, 'is-active') && (nowActive = document.getElementsByClassName('js-e-headermenu__parent is-active')[0])) {
        nowActive.classList.remove('is-active');
      }
      isActiveCtl(this.parentNode);
      return e.stopPropagation;
    }), false);
    i++;
  }
}

Confirm = (function() {
  function Confirm(el) {
    this.message = el.getAttribute('data-confirm');
    if (this.message) {
      el.form.addEventListener('submit', this.confirm.bind(this));
    } else {
      console && console.warn('No value specified in `data-confirm`', el);
    }
  }

  Confirm.prototype.confirm = function(e) {
    if (!window.confirm(this.message)) {
      return e.preventDefault();
    }
  };

  return Confirm;

})();

window.onload = function() {
  var deletebtns, headerfixing, main, side, sidewrap;
  ready();
  if (sidewrap = document.getElementsByClassName('l-content--side__inner')[0]) {
    side = sidewrap.offsetHeight;
    main = document.getElementsByClassName('l-content--outer')[0].offsetHeight;
    if (side < (main - 10)) {
      fixSidebar();
      window.addEventListener('scroll', (function(e) {
        fixSidebar();
      }), false);
    }
  }
  if (!(headerfixing = document.getElementsByClassName('l-header--fix is-active')[0])) {
    fixHeader();
    window.addEventListener('scroll', (function(e) {
      fixHeader();
    }), false);
  }
  if (deletebtns = document.querySelectorAll(".js-link--delete")) {
    Array.from(deletebtns).forEach(function(el) {
      new Confirm(el);
    });
  }
  if (window.location.hash === "#js-success-signup") {
    showSuccessLogin();
  }
};

fixHeader = function() {
  var jstarget, target, y;
  y = document.documentElement.scrollTop || document.body.scrollTop;
  target = document.getElementsByClassName('l-header--fix')[0];
  if (y >= 140 && !hasClass(target, 'is-active')) {
    if (jstarget = document.getElementsByClassName('js-e-headermenu__parent is-active')[0]) {
      isActiveCtl(jstarget);
    }
    target.classList.add('is-active');
  } else if (y < 140 && hasClass(target, 'is-active')) {
    if (jstarget = document.getElementsByClassName('js-e-headermenu__parent is-active')[0]) {
      isActiveCtl(jstarget);
    }
    target.classList.remove('is-active');
  }
};

fixSidebar = function() {
  var main, offtop, side, sidewrap, winh, wrapper, y;
  winh = document.documentElement.clientHeight || document.body.clientHeight;
  sidewrap = document.getElementsByClassName('l-content--side__inner')[0];
  wrapper = document.getElementsByClassName('l-content--outer')[0];
  side = sidewrap.offsetHeight + 20;
  main = wrapper.offsetHeight;
  y = document.documentElement.scrollTop || document.body.scrollTop;
  offtop = wrapper.offsetTop;
  if (main < side) {
    sidewrap.classList.remove('is-fix');
    sidewrap.classList.add('is-abs');
  } else if (y > offtop + side - winh && y <= offtop + main - winh) {
    sidewrap.classList.add('is-fix');
    sidewrap.classList.remove('is-abs');
  } else if ((y > offtop + main - winh) && (side < main)) {
    sidewrap.classList.remove('is-fix');
    sidewrap.classList.add('is-abs');
  } else if (hasClass(sidewrap, 'is-fix') || hasClass(sidewrap, 'is-abs')) {
    sidewrap.classList.remove('is-fix', 'is-abs');
  }
};

if (jsClip = document.getElementById('js-clip_ctl')) {
  document.getElementById('js-clip_ctl').addEventListener('click', function(e) {
    clipCtl(e.target);
  });
}

clipCtl = function(target) {
  var entry_id, url;
  if (!target.closest('[data-entry_id]')) {
    location.reload();
    return false;
  }
  entry_id = target.closest('[data-entry_id]').getAttribute('data-entry_id');
  if (entry_id === null) {
    location.reload();
    return false;
  }
  url = "/mypage/ctl_clip?entry_id=" + entry_id;
  getAjax(url, triggerClip);
};

triggerClip = function(xhttp) {
  var btn, data;
  data = JSON.parse(xhttp.responseText);
  notificationFlash({
    message: data.message,
    notifiType: data.type
  });
  btn = document.getElementById('js-clip_ctl');
  if (data.status === 'create') {
    btn.innerHTML = '<span class="fas fa-check-circle p-main__nums__clip"/>';
  } else if (data.status === 'delete') {
    btn.innerHTML = '<span class="far fa-check-circle p-main__nums__clip"/>';
  }
};

ready = function() {
  const loadExternalSDK = function() {
    loadFacebookSDK();
    loadTwitterSDK();
  };

  if (window.scrollY > 0) {
    loadExternalSDK();
  } else {
    setTimeout(loadExternalSDK, 2500);
  }
};

loadFacebookSDK = function() {
  window.fbAsyncInit = initializeFacebookSDK;
  return loadJsFile("//connect.facebook.net/ja_JP/all.js#xfbml=1");
};

initializeFacebookSDK = function() {
  return FB.init({
    appId: 725368744208860,
    channelUrl: '//vokka.jp/channel.html',
    status: true,
    cookie: true,
    xfbml: true
  });
};

loadTwitterSDK = function() {
  return loadJsFile("//platform.twitter.com/widgets.js");
};
