// WebpackerはminimizerとしてUglifyを使っているので、Tree ShakingのためにDeep Importする
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle as fasCheckCircle, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(
  faFacebookF,
  faTwitter,
  farCheckCircle,
  fasCheckCircle,
  faChevronRight,
  faChevronLeft
);
dom.watch();
