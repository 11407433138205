var _validFileExtensions, exports, getCsrfToken;

hasClass = function(target, className) {
  if (target.classList) {
    return target.classList.contains(className);
  } else {
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(target.className);
  }
};

isActiveCtl = function(target) {
  if (hasClass(target, 'is-active')) {
    target.classList.remove('is-active');
  } else {
    target.classList.add('is-active');
  }
};

loadJsFile = function(file) {
  var start, tagjs;
  tagjs = document.createElement("script");
  start = document.getElementsByTagName("script")[0];
  tagjs.async = true;
  tagjs.src = file;
  start.parentNode.insertBefore(tagjs, start);
};

getAjax = function(url, callback) {
  var xmlhttp;
  xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function() {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      return callback(xmlhttp);
    }
  };
  xmlhttp.open("GET", url, true);
  xmlhttp.send();
};

postAjax = function(url, callback, data, action, token) {
  var xmlhttp;
  if (token == null) {
    token = null;
  }
  xmlhttp = new XMLHttpRequest();
  xmlhttp.open(action, url);
  xmlhttp.onreadystatechange = function() {
    if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
      return callback(xmlhttp);
    }
  };
  xmlhttp.setRequestHeader("X-CSRF-Token", getCsrfToken());
  xmlhttp.setRequestHeader("Content-Type", "application/json");
  if (token !== null) {
    xmlhttp.setRequestHeader("Authorization", "Bearer " + token);
  }
  xmlhttp.send(JSON.stringify(data));
};

getCsrfToken = function() {
  var elem, l, len1, meta;
  meta = document.getElementsByTagName('meta');
  for (l = 0, len1 = meta.length; l < len1; l++) {
    elem = meta[l];
    if (elem.name === 'csrf-token') {
      return elem.content;
    }
  }
};

extend = function() {
  var i, key;
  i = 1;
  while (i < arguments.length) {
    for (key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        arguments[0][key] = arguments[i][key];
      }
    }
    i++;
  }
  return arguments[0];
};

document.getElementById('js-flash').addEventListener('click', function(e) {
  if (hasClass(e.target, 'js-alert')) {
    notificationRemove();
  }
});

notificationFlash = function(options) {
  var option, symbol, timeout, wrap;
  option = options;
  document.getElementsByClassName('c-flash__txt')[0].textContent = option.message;
  symbol = document.getElementsByClassName('c-flash__symbol')[0];
  wrap = document.getElementById('js-flash');
  if (option.notifiType === 'alert') {
    symbol.textContent = '✕';
    wrap.classList.add('js-alert');
    timeout = setTimeout('notificationRemove()', 8000);
  } else {
    symbol.textContent = '○';
    wrap.classList.add('js-notice');
    timeout = setTimeout('notificationRemove()', 1500);
  }
};

notificationRemove = function() {
  var notification, symbol;
  notification = document.getElementById('js-flash');
  symbol = document.getElementsByClassName('c-flash__symbol')[0];
  if (hasClass(notification, 'js-alert') || hasClass(notification, 'js-notice')) {
    notification.classList.remove('js-alert', 'js-notice');
    document.getElementsByClassName('c-flash__symbol')[0].textContent = '';
    document.getElementsByClassName('c-flash__txt')[0].textContent = '';
  }
};

trueWidth = function(element) {
  var computedStyle, contentWidth;
  computedStyle = getComputedStyle(element);
  contentWidth = element.clientWidth;
  contentWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
  return contentWidth;
};

getTranslateX = function(obj) {
  var style, transform, xT;
  style = obj.style;
  transform = style.transform || style.webkitTransform || style.mozTransform;
  xT = transform.match(/translate\([-]?([0-9]+(px|em|%|ex|ch|rem|vh|vw|vmin|vmax|mm|cm|in|pt|pc))\)/);
  if (xT) {
    return xT[1];
  } else {
    return '0';
  }
};

myLazyLoad = new LazyLoad({
  elements_selector: '.js-lazyload',
  // load_delay: 300,
  to_webp: true
});

_validFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];

validateSingleInput = function(oInput) {
  var blnValid, j, sCurExtension, sFileName;
  if (oInput.type === 'file') {
    sFileName = oInput.value;
    if (sFileName.length > 0) {
      blnValid = false;
      j = 0;
      while (j < _validFileExtensions.length) {
        sCurExtension = _validFileExtensions[j];
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
        j++;
      }
      if (!blnValid) {
        alert('このファイルは扱えません。次のファイルフォーマットが取り扱えます：' + _validFileExtensions.join(', '));
        oInput.value = '';
        return false;
      }
    }
  }
  return true;
};

if (!Array.from) {
  Array.from = (function() {
    var isCallable, maxSafeInteger, toInteger, toLength, toStr;
    toStr = Object.prototype.toString;
    isCallable = function(fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    toInteger = function(value) {
      var number;
      number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    maxSafeInteger = Math.pow(2, 53) - 1;
    toLength = function(value) {
      var len;
      len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };
    return function(arrayLike) {
      var A, C, T, items, k, kValue, len, mapFn;
      C = this;
      items = Object(arrayLike);
      if (arrayLike === null) {
        throw new TypeError('Array.from requires an array-like object - not null or undefined');
      }
      mapFn = arguments.length > 1 ? arguments[1] : void 0;
      T = void 0;
      if (typeof mapFn !== 'undefined') {
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }
      len = toLength(items.length);
      A = isCallable(C) ? Object(new C(len)) : new Array(len);
      k = 0;
      kValue = void 0;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      A.length = len;
      return A;
    };
  })();
}

openViewMore = function(button) {
  var list;
  list = document.getElementById('js--list--trendlist');
  if (hasClass(button, "is-active")) {
    button.classList.remove('is-active');
    list.classList.remove('is-active');
  } else {
    button.classList.add('is-active');
    list.classList.add('is-active');
  }
};

showSuccessLogin = function() {
  notificationFlash({
    message: "ログインしました",
    notifiType: "notice"
  });
};
